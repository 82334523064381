export default defineComponent({
  props: {
    assessment: {
      type: Object,
      required: true
    }
  },
  computed: {
    link: function link() {
      return "/".concat(this.assessment.id, "/value-chain");
    }
  }
});